export enum LOGISTICS_COMPONENTS {
  ALL = 'All',
  AIRCRAFT_OPERATE_IN_AIRPORT = 'Aircraft_Operate_In_Airport',
  AIRCRAFT_PARKING_OPTION = 'Aircraft_Parking_Option',
  AIRCRAFT_RESTRICTION = 'Aircraft_Restriction',
  AIRCRAFT_SPOT_ACCOMMODATION = 'Aircraft_Spot_Accommodation',
  AIRCRAFT_TOW_BAR_REQUIREMENT = 'Aircraft_Tow_Bar_Requirement',
  AIRPORT_EQUIPMENT = 'Airport_Equipment',
  AIRPORT_FACILITY = 'Airport_Facility',
  ARRIVAL_EXPECTED_PROCEDURE = 'Arrival_Expected_Procedure',
  ARRIVAL_CREW_PASSENGER_PASSPORT_HANDLING = 'Arrival_Crew_Passenger_Passport_Handling',
  ARRIVAL_LUGGAGE_HANDLING = 'Arrival_Luggage_Handling',
  ARRIVAL_MEETING_POINT = 'Arrival_Meeting_Point',
  DEPARTURE_EXPECTED_PROCEDURE = 'Departure_Expected_Procedure',
  DISABILIITIES_ACCOMMODATION_AVAILABILITY = 'Disabilities_Accomomodation_Availability',
  GENDEC_ADDITIONAL_PROCEDURE = 'GenDec_Additional_Procedure',
  PARKING_DIFFICULTY_MONTHS = 'Parking_Difficulty_Months',
  TOW_BAR_REQUIREMENT_SCENARIO = 'Tow_bar_Requirement_Scenario',
  AVAILABLE_FACILITIES = 'Available_Facilities',
}
