export enum CIQ_LOGISTICS_CREW_PAX_GENDEC_INFO {
  GENDEC_INFO = 'Gendec Info',
  IS_GENDEC_REQUIRED = 'GenDec Required',
  SPECIFIC_GENGEC_TYPE = 'Specific GenDec Type Required',
  GENDEC_ADDITIONAL_PROCEDURES = 'GenDec Additional Procedures',
  GENDIC_FILE = 'GenDec File Path',
  IS_GENDEC_ASSITANCE = 'GenDec Assistance Possible',
  ON_BOARD_CREW_PAX_CUST_CLEARANCE = 'Off Terminal Clearance (OTC) Available',
  ADVANCE_NOTICE_ON_BOARD_CREW_PAX_CUST_CLEARANCE = 'OTC Advanced Notice Needed',
  ADVANCE_NOTICE_UNITS = 'Advance Notice Units',
}
