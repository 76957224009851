export enum GROUND_LOGISTICS_PARKING {
  PARKING_DIFFICULTY_MONTHS = 'Seasonal Peak Months',
  OVER_NIGHT_PARKING_ISSUES = 'Any Overnight Parking Challenges',
  PARKING_DURATION = 'Max Parking Allowed',
  PARKING_DURATION_UNIT = 'Parking Duration Unit',
  PARKING_LOCATION_NEARBY = 'Suggested Parking Alternatives',
  TYPES_OF_AIRCRAFT_OPERATED = 'Types of Aircraft Operating',
  AIRCRAFT_PARKING_LOCATION = 'Aircraft Parking Location',
  AIRCRAFT_PARKING_OPTIONS = 'Aircraft Parking Options',
  AIRCRAFT_SPOT_ACCOMMODATION = 'Aircraft Spot Accommodation',
  AIRCRAFT_TYPES = 'Aircraft Types',
  TOW_BAR_REQUIRED = 'Towbar Required',
  TOWBAR_REQUIREMENT_SCENARIOS = 'Towbar Requirement Scenarios',
  AIRCRAFT_TOW_BAR_REQUIREMENT = 'Aircraft Towbar Requirement',
  AIRCRAFT_RESTRICTIONS = 'Aircraft Restrictions',
  RESTRICTION_TYPE = 'Restriction Type',
  MTOW = 'MTOW',
  MTOW_UNIT = 'MTOW Unit',
  AIRCRAFT_LOGISTICS_DOCUMENTATION = 'Aircraft Logistics Documentation',
  AVAILABLE_GROUND_SERVICE_EQUIPMENT = 'Available Ground Service Equipment',
  EQUIPMENT_TYPES = 'Equipment Types',
}