import { CoreModel, LogisticsComponentModel } from './index';
import { LOGISTICS_COMPONENTS } from '../Enums/index';

export class LogisticsComponentOptionsModel extends CoreModel {
  [LOGISTICS_COMPONENTS.AIRCRAFT_OPERATE_IN_AIRPORT]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.AIRCRAFT_PARKING_OPTION]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.AIRCRAFT_RESTRICTION]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.AIRCRAFT_SPOT_ACCOMMODATION]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.AIRCRAFT_TOW_BAR_REQUIREMENT]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.AIRPORT_EQUIPMENT]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.AIRPORT_FACILITY]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.ARRIVAL_EXPECTED_PROCEDURE]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.ARRIVAL_CREW_PASSENGER_PASSPORT_HANDLING]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.ARRIVAL_LUGGAGE_HANDLING]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.ARRIVAL_MEETING_POINT]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.DEPARTURE_EXPECTED_PROCEDURE]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.DISABILIITIES_ACCOMMODATION_AVAILABILITY]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.GENDEC_ADDITIONAL_PROCEDURE]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.PARKING_DIFFICULTY_MONTHS]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.TOW_BAR_REQUIREMENT_SCENARIO]: LogisticsComponentModel[];
  [LOGISTICS_COMPONENTS.AVAILABLE_FACILITIES]: LogisticsComponentModel[];
}
