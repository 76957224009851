export enum CIQ_LOGISTICS_CREW_PAX_AVAILABLE_FACILITY {
  AVAILABLE_FACILITY_AND_OPERATING_TIME_INFO = 'Available Facilities and Operating Time Information',
  AIRPORT_FACILITY = 'Airport Facilities',
  AVAILABLE_FACILITIES = 'Available Facilities',
  MAIN_TERMINAL_OPERATING_HOURS = 'Main Terminal Operating Hours (LT)',
  OPERATING_HOURS_OF_CIQ_AT_THE_MAIN_TERMINAL = 'Operating Hours of CIQ at the Main Terminal (LT)',
  OPERATING_HOURS_OF_THE_VIP_AREA_TERMINAL = 'Operating Hours of the VIP Area/Terminal (LT)',
  OPERATING_HOURS_OF_THE_GAT = 'Operating hours of the GAT (LT)',
  IS_CIQ_REQUIRED_MAIN_TERMINAL = 'Is CIQ Required Main Terminal',
  IS_CIQ_OVER_TIME = 'Is CIQ Over Time',
  VIP_USAGE_COST = 'VIP Usage Cost (Local Currency)',
  VIP_FEE_TYPE = 'VIP Fee Type',
  IS_OVER_TIME_POSSIBLE = 'Is Over Time Possible',
  OVER_TIME_COST = 'Over Time Cost',
  OVER_TIME_TYPE = 'Over Time Type',
  GAT_COST = 'GAT Cost (Local Currency)',
  GAT_COST_TYPE = 'GAT Cost Type',
  CREW_PAX_PRIORITY = 'Crew PAX Priority',
  CREW_PAX_PRIORITY_EXPLANATION = 'Crew Pax Priority Explanation',
  CIQ_AVAILABLE_AT_GAT_FBO = 'CIQ Available at GAT FBO',
  CIQ_HOURS_LIMITED = 'CIQ Hours Limited',
}
